.outline-unselected {
  background-color: var(--background-light) !important;
  border: 1px solid var(--interactive) !important;
}
.outline-unselected > label {
  color: var(--interactive) !important;
}

.outline-unselected > label:after {
  border-color: var(--interactive) !important;
}
