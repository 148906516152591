.rbc-header {
  color: var(--blue-warm-vivid-80);
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
  border-bottom: 0.8px solid rgba(11, 71, 120, 0.5);

  border-left: none !important;
  border-right: none !important;
}

@media (max-width: 575px) {
  .rbc-header {
    font-size: var(--font-size-scale-up-01);
  }
}

.rbc-month-view {
  border: none;
}

.rbc-today {
  /* blue-warm-vivid-50 com 15% de opacidade (pedido da sgd) */
  background-color: hsla(217, 81%, 53%, 0.15);
}

.rbc-date-cell {
  text-align: center;
  padding-right: 0;
}

.rbc-date-cell > button {
  font-weight: 500;
  padding: var(--spacing-scale-base) 0;
}

.rbc-day-bg {
  border-left: none !important;
  border-right: none !important;
}

.rbc-off-range-bg {
  background-color: initial;
}

.rbc-event {
  padding: 0 !important;
  background-color: var(--gray-5);
  color: var(--gray-80);
  height: 36px;
}

.rbc-event-content {
  height: 100%;
}

.rbc-selected {
  background-color: var(--gray-5) !important;
}

.rbc-row:nth-child(n + 2) > .rbc-row-segment > .rbc-event {
  margin-top: 4px !important;
}

.rbc-off-range > button {
  color: var(--gray-40);
}

.rbc-row-content {
  min-height: 80px !important;
}

.rbc-month-row {
  overflow: visible !important;
}

.rbc-calendar {
  display: flex;
  flex-direction: column;
}

.rbc-row:last-child {
  margin-bottom: 12px;
}

@media (min-width: 319px) {
  .rbc-show-more {
    font-size: var(--font-size-scale-down-03);
  }
}

@media (min-width: 380px) {
  .rbc-show-more {
    font-size: var(--font-size-scale-down-02);
  }
}

@media (min-width: 450px) {
  .rbc-show-more {
    font-size: var(--font-size-scale-down-01);
  }
}
